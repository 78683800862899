/* Navbar.css */
.header-nav {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;

}

nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    color: #222222;
}

.nav__logo {
    transition: color 0.4s;

}

.nav__toggle,
.nav__close {
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
    display:flex;
}



.nav__item.active{
    /*padding: 15px 10px;*/
    /*border: solid 1px rgba(69, 127, 195, 1);*/
    border-radius: 0px;
    background-color: #1890FF;
    font-size: 12px;
    text-transform: capitalize;
    color: white;
    /* pointer-events: none; */
}
.nav__item a:hover{
    color: white;
}

.nav__item a:active{
    color: white;
}

.nav__item a:visited{
    color: white;
}

.e-paper-btn{
    border: none;
    font-size: 14px;
    /*padding: 15px;*/
    color: #fff;
    background: linear-gradient(94.61deg, #41479B 0.03%, #0A429C 50.83%, #478AF6 100.6%);
    margin-left: 1px;
    margin-right: 0;
    /*background-color: #082DED*/
    text-transform: uppercase;

}
.nav__item:hover:not(.active):not(.e-paper-btn):not(.e-paper-btn-ot):not(.new-search-btn){
    background-color:#1890FF;
    color: white;
    font-size: 12px;

}

/*.nav__menu .nav__list li:last-child {*/
/*    margin-right: 0; !* Remove margin from the last item *!*/
/*}*/

/*!* Push the last two items to the flex end *!*/
/*.nav__menu .nav__list li:nth-last-child(1),*/
/*.nav__menu .nav__list li:nth-last-child(2) {*/
/*    margin-left: auto;*/
/*}*/

@media screen and (max-width: 1023px) {
    .nav__menu {
        position: fixed;
        top:0;
        right: -100%;
        background: rgba(20, 10, 71,1);
        backdrop-filter: blur(40px);
        -webkit-backdrop-filter: blur(40px);
        width: 90%;
        height: 200vh;
        padding: 3rem 3rem 0;
        transition: right 0.4s;
        z-index: 1;
    }

    .header-mid{
        padding-top: 10px;
        backdrop-filter: blur(0px);
        background: linear-gradient(90.76deg, #0C2DEA -40.52%, #030A34 4.5%,#000000 55.64%, #360205 95.08%, #FD0819 1000.49%);
        /* padding-bottom: 25px; */
        /* padding: 25px 0; */
        /*border-bottom: 1px solid #dadada;*/
        position: relative;
        z-index: 11;
    }
    .header-mobile{
        background: rgb(16, 20, 137);
        position: relative;
        width: 100%;
        /*background: #FFF;*/
        z-index: -1;
        -webkit-box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow:    0px 3px 2px 0px rgba(0, 0, 0, 0.05);
        box-shadow:         0px 3px 2px 0px rgba(0, 0, 0, 0.05);
        -webkit-backface-visibility: hidden;
    }
    .match-btn{
        /*border: 2px solid rgba(69, 127, 195, 1);*/
        background-color: transparent;
        color: #FFFFFF;
        /*padding: 15px;*/
        font-family: "Open Sans", sans-serif;
        text-transform: uppercase;
        /*margin-right: -50px;*/

    }

    .shop-btn{
        /*border: 2px solid rgba(69, 127, 195, 1);*/
        background-color: transparent;
        color: #FFFFFF;
        /*padding: 15px 20px;*/
        font-family: "Open Sans", sans-serif;
        text-transform: uppercase;
    }

    .new-login-btn-en-mobile{
        /*line-height: 1;*/
        border: 2px solid #082DED;
        background-color: transparent;
        border-color: #082DED;
        color: #FFFFFF;
        border-radius: 50px;
        padding: 4px 15px;
        margin: 5px;
        font-family: "Open Sans", sans-serif
    }

    .sign-login-btn-en-mobile{
        margin: 5px;
        /*line-height: 1;*/
        border: 2px solid #082DED;
        background-color: #082DED;
        color: #FFFFFF;
        border-radius: 50px;
        padding: 4px 15px;
        font-family: "Open Sans", sans-serif
    }

    .btn-search{
        font-size: 15px;
        border: 0;
        color: #fff;
        background-color: transparent;
        /*border-color: #202333;*/
        border-radius: 50%;
        /*box-shadow: rgba(0, 0, 0, 0.16) 2px 4px 22px 0px;*/
        /*padding: 7px 15px;*/
        /*margin: 5px;*/
        text-align: center;
    }
    .nav__item{
        font-size: 12px;
        border-radius: 0 !important;
        padding: 10px;
        text-wrap: nowrap;
    }

    .nav__item:hover:not(.active):not(.e-paper-btn):not(.e-paper-btn-ot):not(.new-search-btn){
        background-color:#1890FF;
        color: white;
        font-size: 12px;
        border-radius: 0px !important;

    }

    .nav__list {
        row-gap: 0.8rem !important;
    }

}
@media screen and (max-width: 300px) {
    .nav__menu {
        padding: 3rem 1rem 2rem;
    }

    .nav__list {
        row-gap: 1rem;
    }

    .nav__link,
    .nav__cta {
        font-size: 14px
    }

}

@media screen and (max-width: 540px) {
    .nav__menu {
        padding: 3.5rem 1rem 2rem;
    }
}

.nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
}

.nav__link {
    color: white;
    transition: color 0.4s;
}

/*.nav__link:hover {*/
/*    color: var(--first-color);*/
/*}*/

.nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}

.show-menu {
    left: 0;
}


.nav__menu_mobile {
    z-index: 10000;
    position: fixed;
    top: 0;
    right: -100%;
    background-color: hsla(0, 0%, 6%, 0.2);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    width: 80%;
    height: 100%;
    padding: 4rem 3rem 0;
    transition: right 0.4s;
}
@media screen and (min-width: 1023px) {
    /*.nav {*/
    /*    height: calc(var(--header-height) + 1rem);*/
    /*}*/
    .nav__toggle,
    .nav__close {
        display: none;
    }

    .nav__list {
        display: flex;
        flex-direction: row;
        /*column-gap: 2.5rem;*/
        list-style-type: none;
        color: grey;
        height: 100%;
        justify-content: left;
        align-items: center;
        position: relative;
        font-family: "Open Sans", sans-serif;
        line-height: 20px;
        margin-bottom: 0;
    }

    /*.nav__list li:last-child {*/
    /*    margin-right: 0; !* Remove margin from the last item *!*/
    /*}*/

    /*.nav__list li:nth-last-child(-n+3) {*/
    /*    margin-left: auto; !* Push the last 3 items to the right *!*/
    /*}*/

    .nav__item {
        margin-top: -1px;
        padding: 15px 10px;
        font-size: 12px;
        text-transform: capitalize;
        color: white;
        cursor: pointer;
        text-wrap: nowrap;
    }

    .match-btn{
        /*border: 2px solid rgba(69, 127, 195, 1);*/
        background-color: transparent;
        color: #FFFFFF;
        /*padding: 15px !important;*/
        font-family: "Open Sans", sans-serif;
        /*margin-right: -50px;*/
        margin-left: auto;
    }

    .shop-btn{
        /*border: 2px solid rgba(69, 127, 195, 1);*/
        background-color: transparent;
        color: #FFFFFF;
        /*padding: 15px 20px !important;*/
        font-family: "Open Sans", sans-serif;
        /*margin-right: -50px;*/
        margin-left: 0;
    }

}

@media screen and (max-width: 1090px) and (min-width: 1024px){

    .nav__item{
        font-size: 10px;
    }
    .nav__item.active{
        font-size: 10px;
    }
    .mega-menu ul {
        text-wrap: nowrap;
    }
    .nav__item:hover:not(.active):not(.e-paper-btn):not(.e-paper-btn-ot):not(.new-search-btn){
        font-size: 10px;
    }
}

@media screen and (max-width: 1163px) and (min-width: 1090px){
    .nav__item{
        font-size: 11px;
    }
    .nav__item.active{
        font-size: 11px;
    }
    .mega-menu ul {
        text-wrap: nowrap;
    }
    .nav__item:hover:not(.active):not(.e-paper-btn):not(.e-paper-btn-ot):not(.new-search-btn){
        font-size: 11px;
    }
}

